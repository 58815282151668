<template>
	<div>
		<div>
			<el-button @click="handleAdd" size="small" type="primary">新增</el-button>
			<el-button @click="getMatters" size="small">刷新</el-button>
		</div>
		<el-table :data="tableData" v-loading="tableLoading" border stripe
			:header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" class="margin-t-10"
			style="width: 100%;">
			<el-table-column label="#" type="index" width="60"></el-table-column>
			<el-table-column label="创建时间" prop="created_at" width="170px"></el-table-column>
			<el-table-column label="交接事项" prop="matter"></el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total, jumper" :total="total"
				:current-page.sync="queryForm.page" @current-change="getMatters">
			</el-pagination>
			<el-button size="small">确定</el-button>
		</div>

		<el-dialog title="新增交接事项" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
			width="500px">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px">
				<el-form-item label="交接事项" prop="matter">
					<el-input v-model="form.matter" type="textarea"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getMattersAPI,
		addMattersAPI,
	} from '@/api/outpatient.js'

	export default {
		name: 'OutpatientMatters',
		data() {
			return {
				queryForm: {
					page: 1,
					page_size: 10
				},

				dialogTitle: '',
				showDialog: false,
				submitting: false,
				form: {
					matter: '',
				},
				rules: {
					matter: [{
						required: true,
						message: '交接事项不能为空',
						trigger: 'blur'
					}],
				},

				tableData: [],
				total: 0,
				tableLoading: false,
			}
		},
		created() {
			this.getMatters()
		},
		methods: {
			handleAdd() {
				this.showDialog = true
			},
			closeDialog() {
				this.$refs.form.resetFields()
				this.showDialog = false
			},
			handleSubmit() {
				this.$refs.form.validate(isValid => {
					if (!isValid) return

					this.submitting = true
					const copyForm = Object.assign({}, this.form)

					addMattersAPI(copyForm).then(res => {
						this.$message.success(res.msg)
						this.closeDialog()
						this.getMatters()
					}).finally(() => this.submitting = false)
				})
			},

			getMatters() {
				this.tableLoading = true

				getMattersAPI(this.queryForm).then(res => {
					this.tableData = res.data
					this.total = res.total
					this.tableLoading = false
				})
			},
		}
	}
</script>

<style>
</style>