// 门诊安排
import request from '@/utils/util.js'

// 新增服务记录
export function addServiceRecordAPI(data) {
	return request.post('/out_patient/log', data)
}

// 获取服务记录统计数据
export function getServiceRecordStatisticsAPI(data) {
	return request.get('/out_patient/log', data)
}

// 获取值班人员
export function getWatchkeeperAPI() {
	return request.get('/out_patient/duty')
}

// 获取交接事项
export function getMattersAPI(data) {
	return request.get('/out_patient/remark', data)
}

// 新增事项
export function addMattersAPI(data) {
	return request.post('/out_patient/remark', data)
}

// 获取今日就诊保障记录数据
export function getHealthDiagnoseListAPI(data) {
	return request.get('/health_diagnose/list', data)
}

// 获取今日疫苗接种记录
export function getVaccineRecordListAPI(data) {
	return request.get('/vaccine/record_list', data)
}

// 获取今日住院保障记录
export function getHospitalizedRecordListAPI(data) {
	return request.get('/hospitalized/record_list', data)
}